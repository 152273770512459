<template>
  <v-container
    id="login-view"
    class="fill-height"
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-slide-y-transition appear>
          <material-card
            light
            max-width="350"
            rounded
            class="mx-auto"
            color="primary"
            full-header
          >
            <template #heading>
              <div class="text-center pa-5">
                <div class="text-h4 font-weight-bold white--text">
                  {{ $t('forgotPassword.title') }}
                </div>
              </div>
            </template>
            <v-card-text class="text-center">
              <div
                v-if="successfulPasswordReset"
                class="text-h4 mt-4"
              >
                {{ $t('forgotPassword.success') }}
              </div>
              <validation-observer
                v-else
                v-slot="{ handleSubmit }"
              >
                <form @submit.prevent="handleSubmit(submit)">
                  <div class="text-center font-weight-light">
                    {{ $t('forgotPassword.instructions') }}
                  </div>

                  <validation-provider
                    v-slot="{ errors }"
                    rules="required|email"
                    :name="$t('email').toLowerCase()"
                  >
                    <v-text-field
                      v-model="form.email"
                      :error-messages="errors"
                      color="primary"
                      :placeholder="$t('email')"
                      prepend-icon="mdi-email"
                      validate-on-blur
                    />
                  </validation-provider>

                  <SubmitButton
                    :label="$t('submit')"
                    type="submit"
                    class="my-4"
                  />

                  <div class="d-flex justify-space-around">
                    <router-link
                      to="/login"
                      class="font-weight-bold text-decoration-none"
                    >
                      {{ $t('cancel') }}
                    </router-link>
                  </div>
                </form>
              </validation-observer>
            </v-card-text>
          </material-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  import serverError from '../mixins/serverError';
  export default {
    name: 'ForgotPasswordView',

    mixins: [serverError],

    data: () => ({
      successfulPasswordReset: false,
      form: {
        email: null,
      },
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        accountCreationEnabled: 'app/accountCreationEnabled',
        socialLoginEnabled: 'app/socialLoginEnabled',
      }),
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { email } = this.form
          const payload = { email };
          this.$store.dispatch('auth/forgotPassword', payload)
            .then(response => {
              this.successfulPasswordReset = true;
            })
            .catch(error => {
              this.displayErrors(error);
            });
        }
      },
    },
  }
</script>
